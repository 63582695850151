@import "colors.top5b.css";

.navBarBox {
  visibility: hidden;  
}

.App-logo {
  height: 37px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 6s linear;
  }
}


.chat_bot {
  position:fixed ;
  bottom: 0;
  right: 0;
}

.chat_bot2 {
  visibility: hidden;
    display: none;
}

@media screen and (max-width: 948px) {
  .chat_bot {
    visibility: hidden;
    display: none;
  }
  .chat_bot2 {
     display:inline;
    position:relative ;
  visibility: inherit;
  
  }
}



.chatBot {
  position:fixed ;
  bottom: 0;
  right: 0;
}




.sys-op {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  position:fixed ;
  top: 0;
  left: 0;
  padding: 30px;
  height: 100%;
  text-align: left;
  z-index: 1;
  }
  



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

[data-amplify-authenticator] {
  --amplify-colors-background-secondary: #070707;
}
 


.react-chatbot-kit-chat-container {
  background-color: rgb(255, 0, 0);

  
}

.react-chatbot-kit-chat-inner-container {
  background-color: rgb(255, 251, 0);
}

.react-chatbot-kit-chat-header {
  background-color: rgb(255, 238, 0);
}

.react-chatbot-kit-chat-input-container {
  background-color: rgb(225, 250, 0);
}

.react-chatbot-kit-chat-message-container {
  background-color: rgb(0, 0, 0);
}



.react-chatbot-kit-chat-message-container::-webkit-scrollbar {
  display:none;
  color: #474747;
  
}

.react-chatbot-kit-user-chat-message {
  background-color: #ff0000;
  color: #585858;

}

.react-chatbot-kit-chat-input {
  background-color: #ffffff;
  border-bottom-left-radius: 0px;
}

.react-chatbot-kit-chat-input-form {
  background-color: #0F4C5C;
   
}

.react-chatbot-kit-chat-input::placeholder {
  
}

.react-chatbot-kit-chat-btn-send {
  
  border-bottom-right-radius: 0px;
}

.react-chatbot-kit-chat-btn-send-icon {
  
}

.react-chatbot-kit-chat-bot-message-container {
  
}

.react-chatbot-kit-chat-bot-avatar-container {
  background-color: rgb(229, 255, 0);
}

.react-chatbot-kit-chat-bot-avatar-icon {
  background-color: rgb(251, 255, 0);
}

.react-chatbot-kit-chat-bot-avatar-letter {
 
}

.react-chatbot-kit-chat-bot-message {
  background-color: rgb(255, 238, 0);
}

.react-chatbot-kit-chat-bot-message-arrow {
  
  border-color: black;
}

.react-chatbot-kit-chat-bot-loading-icon-container {
  background-color: rgb(255, 230, 0);
  border-color: black;
}

.chatbot-loader-container {

  border-color: black;
}

#chatbot-loader #chatbot-loader-dot1 {
  
  border-color: black;
}

#chatbot-loader #chatbot-loader-dot2 {
  
  border-color: black;
}

#chatbot-loader #chatbot-loader-dot3 {
  
  border-color: black;
}

.react-chatbot-kit-error {
  background-color: rgb(255, 230, 0);
  border-color: black;
}

.react-chatbot-kit-error-container {
  background-color: rgb(255, 238, 0);
  border-color: black;
}

.react-chatbot-kit-error-header {
  background-color: rgb(238, 255, 0);
  border-color: black;
}

.react-chatbot-kit-error-docs {
  background-color: rgb(248, 252, 0);
  border-color: black;
}

