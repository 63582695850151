.App {
  background-color: #000000;
}


.Toastify__toast--warning {
  background: #000000 !important;
  color: #ffffff !important;
  border: 2px solid #9A031E;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
    
}

.Toastify__toast--success {
  background: #000000 !important;
  color: #ffffff !important;
  border: 2px solid #1e839c;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
    
}

.Toastify__toast--error {
  background: #000000 !important;
  color: #ffffff !important;
  border: 2px solid #9A031E;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
    
}

.Toastify__toast--info {
  background: #000000 !important;
  color: #ffffff !important;
  border: 2px solid #1e839c;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
    
}

.Toastify__progress-bar--info {
  background: #1e839c  !important;
}
.Toastify__progress-bar--success {
  background: #1e839c !important;
}
.Toastify__progress-bar--warning {
  background: #FB8B24 !important;
} 
.Toastify__progress-bar--error {
  background: #9A031E !important;
}

.toastBody {
  font-family: "Atlas Grotesk Web", Arial, Helvetica, sans-serif;
  color: #ffffff; /* #10171D */
  font-size: 0.875rem !important;
}
.toastProgress {
  background: #FB8B24!important;
}

.BK-button {
  background-color: #000000 ;
  color: white;
}


.GR-button {
  background-color: #4b4b4b ;
  color: rgb(179, 179, 179);
}

.App-title {
  color: #ca0c2f;
}

.App-user {
  color:  #1e839c;
}

.App-bank {
  color: #d4d3d3;
}

.App-SDT {
  color: #0F4C5C;
}

.App-running {
  color: #FB8B24;
}

.App-processing {
  color: #FB8B24;
}

.App-stopped {
  color: #d4d3d3;
}

.Misc-button {
  background-color: #0F4C5C;
  color: white ;
}

.AT-button {
  background-color: #0F4C5C;
  color: white;
}

.SO-button {
  background-color:  #9A031E;
  color: white;
}

.RDP-button {
  background-color: #0F4C5C;
  color: white;
}

.RB-button {
  background-color: #FB8B24;
  color: white;
}

.RT-button {
  background-color: #FB8B24;
  color: white;
}

.ST-button {
  background-color: #0F4C5C;
  color: white;
}

.SP-button {
  background-color: #ce5a12;
  color: white;
}

.PR-button {
  background-color: #ce5a12;
  color: white;
}



